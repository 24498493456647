import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';

import { BLOCKCHAINS } from '@zealy/utils';

import { config } from './config';
import { Disclaimer } from './Provider';

const InternalRainbowProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <RainbowKitProvider
      theme={darkTheme({
        accentColor: 'var(--color-text-brand-primary)',
        fontStack: 'system',
      })}
      appInfo={{
        disclaimer: Disclaimer,
      }}
      initialChain={BLOCKCHAINS['eth-mainnet'].chainId}
    >
      {children}
    </RainbowKitProvider>
  );
};

export const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={config} reconnectOnMount={false}>
      <InternalRainbowProvider>{children}</InternalRainbowProvider>
    </WagmiProvider>
  );
};
