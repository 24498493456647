import React, { useEffect, useState } from 'react';
import { ConnectButton as Base } from '@rainbow-me/rainbowkit';
import { useTranslations } from 'next-intl';
import { useAccount, useDisconnect, useSignMessage, useSwitchChain } from 'wagmi';

import { Button } from '@zealy/design-system';
import { WalletConnectBrand } from '@zealy/icons';
import { createWalletSession, walletLogin } from '@zealy/queries';
import { AuthError, BLOCKCHAINS } from '@zealy/utils';

import type { CaptchaToken } from '#hooks/useCaptcha';
import { toast } from '#components/Toaster';
import { useAuthError } from '#context/AuthError';
import { useCaptcha } from '#hooks/useCaptcha';

import { LoginProvider } from './LoginProvider';

type WalletButtonWrapperProps = {
  ready: boolean;
  children: React.ReactNode;
};

const useWalletAuth = (
  onSuccess: (() => void) | undefined,
  invitationId: string | undefined,
  getToken: () => Promise<CaptchaToken>,
) => {
  const { setAuthError } = useAuthError();
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { disconnect } = useDisconnect();
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);

  const afterConnect = async () => {
    if (!isConnected) {
      console.log('[WalletConnect]: Not connected');
      return;
    }
    try {
      setAuthError(undefined);

      console.log(`[WalletConnect]: Asking address=${address} to sign-in...`);

      if (!address) return;

      setIsVerifyingAccount(true);

      console.log('[WalletConnect]: Fetching nonce...');

      const nonce = await createWalletSession(address);

      console.log('[WalletConnect]: Preparing message to sign');

      const signedMessage = await signMessageAsync({
        message: nonce.nonce,
      });

      const captchaToken = await getToken();

      if (!captchaToken) {
        toast.error('Missing captcha token');
        return;
      }

      await walletLogin(signedMessage, nonce.id, captchaToken, invitationId);

      console.log('[WalletConnect]: Account verified!');

      onSuccess?.();
    } catch (error: any) {
      console.error('[WalletConnect] Error!: ', error);
      setIsVerifyingAccount(false);

      if (error instanceof AuthError) {
        setAuthError(error);
      } else {
        setAuthError({ name: 'invalid_auth', message: error.message, data: {} });
      }
    } finally {
      // Disconnect wallet from wagmi
      disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return { isVerifyingAccount, afterConnect };
};

const WalletButtonWrapper = ({ ready = false, children }: WalletButtonWrapperProps) => {
  if (!ready) {
    return (
      <div
        aria-hidden={true}
        style={{
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {children}
      </div>
    );
  }

  return <>{children}</>;
};

type WalletButtonContentProps = {
  openConnectModal: () => void;
  isConnected: boolean;
  onSuccess?: () => void;
  invitationId?: string;
  getToken: () => Promise<CaptchaToken>;
};

const WalletButtonContent = ({
  openConnectModal,
  isConnected,
  onSuccess,
  invitationId,
  getToken,
}: WalletButtonContentProps) => {
  const t = useTranslations('login');
  const { isVerifyingAccount, afterConnect } = useWalletAuth(onSuccess, invitationId, getToken);
  const { switchChain } = useSwitchChain();
  const { chain } = useAccount();

  const onClick = () => {
    if (chain?.id !== BLOCKCHAINS['eth-mainnet'].chainId) {
      switchChain({ chainId: BLOCKCHAINS['eth-mainnet'].chainId });
    }
    openConnectModal();
  };

  useEffect(() => {
    if (isConnected) {
      afterConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <Button
      loading={isVerifyingAccount}
      onClick={onClick}
      size="lg"
      className="w-full"
      leftIcon={<WalletConnectBrand className="text-[#3B99FC]" />}
      variant="muted"
    >
      {t('options.login-with', {
        platform: t('options.platforms.wallet'),
      })}
    </Button>
  );
};

export const LoginWalletButton = ({
  onSuccess,
  invitationId,
}: {
  onSuccess?: () => void;
  invitationId?: string;
}) => {
  const { getToken, CaptchaWidget } = useCaptcha('wallet_connection');

  return (
    <LoginProvider>
      <Base.Custom>
        {({ account, chain, openConnectModal, authenticationStatus, mounted }) => {
          const ready = Boolean(mounted && authenticationStatus !== 'loading');

          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          return (
            <WalletButtonWrapper ready={ready}>
              <WalletButtonContent
                openConnectModal={openConnectModal}
                isConnected={!!connected}
                onSuccess={onSuccess}
                invitationId={invitationId}
                getToken={getToken}
              />
            </WalletButtonWrapper>
          );
        }}
      </Base.Custom>
      {CaptchaWidget}
    </LoginProvider>
  );
};
