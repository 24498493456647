'use client';

import { useSessionStorageValue } from '@react-hookz/web';
import { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';

import type { User } from '@zealy/queries';
import { Button, TextField } from '@zealy/design-system';
import { DiscordBrand } from '@zealy/icons';
import { useAuthenticatedUser, useSendOtp } from '@zealy/queries';
import { isValidEmail } from '@zealy/utils';

import { AcceptTerms } from '#components/AcceptTerms';
import { AuthErrorAlert } from '#components/AuthErrorAlert';
import { Logo } from '#components/Logo';
import { toast } from '#components/Toaster';
import { useAuthError } from '#context/AuthError';
import { LoginWalletButton } from '#features/Wallets/EVM/LoginWalletButton';
import { useCaptcha } from '#hooks/useCaptcha';
import { useDidMount } from '#hooks/useDidMount';
import { useOauth2 } from '#hooks/useOAuth2';

type AuthFormProps = {
  variant: 'login' | 'signup';
};

const AuthForm = ({ variant }: AuthFormProps) => {
  const searchParams = useSearchParams();
  const invitationId = searchParams.get('invitationId') ?? undefined;
  const sendOtp = useSendOtp();
  const [isLoading, setIsLoading] = useState(false);

  const { authError } = useAuthError();

  const user = useAuthenticatedUser<User>();
  const { getToken, CaptchaWidget } = useCaptcha('email_connection');

  const { value: redirectUrl } = useSessionStorageValue<string | null>('redirectUrl');

  const router = useRouter();
  const t = useTranslations(variant);
  const [email, setEmail] = useState('');

  const discordOauth2 = useOauth2('discord', invitationId);

  const redirectTo = `${variant === 'login' ? '/signup' : '/login'}${
    invitationId ? `?invitationId=${invitationId}` : ''
  }`;
  const buttonSuffix = variant === 'login' ? 'options.login-with' : 'options.continue-with';

  const openDiscord = () => discordOauth2.open();
  const connectWithEmail = async () => {
    setIsLoading(true);
    try {
      const captchaToken = await getToken();

      if (!captchaToken) return toast.error('Missing Captcha');

      sendOtp.mutate(
        { email, ...captchaToken, invitationId },
        {
          onSuccess: () => {
            router.push(
              `/verify-email?email=${encodeURIComponent(email)}&invitationId=${invitationId ?? ''}`,
            );
          },
          onError: error => {
            console.error('Error sending OTP:', error);
            toast.error(error.message);
          },
        },
      );
    } catch (error) {
      toast.error('Captcha verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.data) {
      if (!user.data.name) {
        router.push(`/create-user${invitationId ? `?invitationId=${invitationId}` : ''}`);
      } else if (authError) {
        router.push(`/cw/_/settings/linked-accounts?error=${searchParams.get('error')}`);
      } else {
        router.push(redirectUrl ?? '/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data]);

  const didMount = useDidMount();

  if (!didMount) return null;

  return (
    <main className="flex flex-row w-full h-full">
      <div className="flex flex-[2_2_0%] flex-col items-center bg-secondary">
        <div className="flex flex-col w-full md:w-[600px] h-full">
          <div className="m-400 flex flex-row justify-between items-center">
            <Link prefetch={false} href="/">
              <Logo />
            </Link>
            <div className="flex flex-row items-center gap-200">
              <span className="hidden md:block body-md text-secondary">
                {t('page-helper-label')}
              </span>
              <Button as={Link} href={redirectTo} variant="muted">
                {t('page-cta-label')}
              </Button>
            </div>
          </div>
          <section className="flex flex-1 flex-col items-center p-400 md:p-1000 md:pt-0 md:justify-center">
            <div className="flex flex-col gap-200 w-full">
              <div className="flex flex-col gap-100 pb-300">
                <h1 className="heading-2">{t('options.title')}</h1>
                <p className="text-secondary body-lg">{t('options.description')}</p>
              </div>
              {variant === 'login' && (
                <>
                  <Button
                    size="lg"
                    leftIcon={<DiscordBrand />}
                    variant="muted"
                    onClick={openDiscord}
                  >
                    {t(buttonSuffix, {
                      platform: t('options.platforms.discord'),
                    })}
                  </Button>
                  <LoginWalletButton onSuccess={user.refetch} invitationId={invitationId} />
                  <div className="flex flex-row items-center gap-200 my-300">
                    <div className="h-[1px] flex flex-1 shrink-0 bg-white/20" />
                    <span className="text-secondary section-heading">{t('options.or')}</span>
                    <div className="h-[1px] flex flex-1 shrink-0 bg-white/20" />
                  </div>
                </>
              )}
              <TextField
                name="email"
                type="email"
                placeholder={t('options.email-placeholder')}
                label="Email address"
                size="lg"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete="email"
                onKeyDown={e => e.key === 'Enter' && connectWithEmail()}
              />

              {authError && <AuthErrorAlert {...authError} />}

              <Button
                color="cta"
                size="lg"
                onClick={connectWithEmail}
                isDisabled={!email || !isValidEmail(email)}
                loading={isLoading}
              >
                {t(buttonSuffix, {
                  platform: t('options.platforms.email'),
                })}
              </Button>
            </div>

            {variant === 'signup' && (
              <div className="mt-800 flex align-center justify-center">
                <AcceptTerms />
              </div>
            )}
          </section>
        </div>
        {CaptchaWidget}
      </div>
      <div className="flex-[3_3_0%] bg-auth bg-cover bg-center content-center hidden md:grid saturate-[1.2]" />
    </main>
  );
};

export default AuthForm;
